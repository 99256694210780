if (
  typeof document.getElementsByClassName("good-place__slider")[0] != "undefined" &&
  document.getElementsByClassName("good-place__slider")[0] != null
) {
  document.querySelectorAll(".good-place__slider").forEach((slider) => {
    const goodPlaceSlider = tns({
      container: slider,
      items: 2,
      loop: false,
      mouseDrag: true,
      gutter: 16,
      controls: false,
      autoplay: true,
      autoplayTimeout: 2000,
      autoplayHoverPause: true,
      nav: true,
      responsive: {
        768: {
          gutter: 24,
          items: 3,
        },
        968: {
          items: 4,
        },
      },
    });
  });
}

if (
  typeof document.getElementsByClassName("best__slider")[0] != "undefined" &&
  document.getElementsByClassName("best__slider")[0] != null
) {
  document.querySelectorAll(".best__slider").forEach((slider) => {
    const featureSlider = tns({
      container: slider,
      items: 1,
      fixedWidth: 330,
      loop: false,
      mouseDrag: true,
      gutter: 16,
      controls: false,
      center: true,
      rewind: true,
      autoplay: true,
      autoplayTimeout: 2000,
      autoplayHoverPause: true,
      nav: true,
      responsive: {
        568: {
          fixedWidth: 450,
          gutter: 24,
        },
        768: {
          autoplay: false,
          items: 1,
          fixedWidth: 550,
        },
        992: {
          items: 1,
          fixedWidth: 660,
        },
        1200: {
          fixedWidth: 430,
          items: 3,
          mouseDrag: false,
          touch: false,
          nav: false,
        },
        1440: {
          fixedWidth: 450,
        },
      },
    });

    featureSlider.goTo("1");
  });
}

if (
  typeof document.getElementsByClassName("opinions__slider")[0] != "undefined" &&
  document.getElementsByClassName("opinions__slider")[0] != null
) {
  document.querySelectorAll(".opinions__slider").forEach((slider) => {
    const opinionsSlider = tns({
      container: slider,
      items: 1,
      loop: false,
      mouseDrag: true,
      gutter: 16,
      nav: true,
      controls: false,
      loop: true,
      autoplay: true,
      autoplayTimeout: 4000,
      autoplayHoverPause: true,
      responsive: {
        768: {
          items: 2,
          gutter: 40,
        },
      },
    });
  });
}

if (
  typeof document.getElementsByClassName("shortblog__slider")[0] != "undefined" &&
  document.getElementsByClassName("shortblog__slider")[0] != null
) {
  const shortblogNav = document.querySelector(".shortblog__nav");
  let shortblogItemsCount = document.querySelectorAll(".shortblog__slider-item-container").length;
  shortblogItemsCount % 2 == 0 ? shortblogItemsCount++ : shortblogItemsCount;
  let buttonAmount = shortblogItemsCount;
  if (window.innerWidth > 992) {
    buttonAmount = shortblogItemsCount / 2;
  }

  for (let index = 0; index <= buttonAmount; index++) {
    shortblogNav.appendChild(document.createElement("button"));
  }

  document.querySelectorAll(".shortblog__slider").forEach((slider) => {
    const shortblogSlider = tns({
      container: slider,
      items: 1,
      mouseDrag: true,
      gutter: 16,
      nav: true,
      navContainer: ".shortblog__nav",
      controls: false,
      loop: true,
      autoplay: true,
      autoplayTimeout: 2000,
      autoplayHoverPause: true,
      responsive: {
        992: {
          autoplayTimeout: 3000,
          items: 2,
          gutter: 40,
        },
      },
    });
  });
}

if (
  typeof document.getElementsByClassName("product-page__carousel-thumbnails")[0] != "undefined" &&
  document.getElementsByClassName("product-page__carousel-thumbnails")[0] != null
) {
  const productPageThumnnailsSlider = tns({
    container: ".product-page__carousel-thumbnails",
    items: 2,
    autoplay: true,
    loop: false,
    mouseDrag: true,
    autoplayHoverPause: true,
    autoplay: true,
    rewind: true,
    gutter: 24,
    nav: false,
    autoplayTimeout: 3000,
  });
}
