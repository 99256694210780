const generateProblemsItems = () => {
  const problemsRows = document.querySelectorAll(".problems__row");

  problemsRows.forEach((element) => {
    for (let itemAmount = 0; itemAmount < 10; itemAmount++) {
      const problemItem = document.createElement("div");
      problemItem.classList.add("problems__item", "px-3", "py-2", "px-md-4", "py-md-3");
      element.appendChild(problemItem);
    }
  });
};

generateProblemsItems();

const problemsColorsAndNames = () => {
  // colors
  const listBg = ["hsl(73, 27%, 94%)", "hsl(311, 38%, 94%)", "hsl(35, 92%, 95%)"];
  const listColor = ["hsla(72, 31%, 47%, 1)", "hsla(312, 36%, 55%, 1)", "hsla(36, 91%, 58%, 1)"];

  // names
  const dataProblems = document.querySelector(".problems")?.getAttribute("data-problems");
  if (dataProblems !== undefined) {
    const listNames = dataProblems.split("/").map((item) => item.trim());

    const problemsItems = document.querySelectorAll(".problems__item");

    const selectedNames = [...listNames];

    problemsItems.forEach((element) => {
      const randomColor = Math.floor(Math.random() * listBg.length);
      element.style.color = listColor[randomColor];
      element.style.backgroundColor = listBg[randomColor];

      if (selectedNames.length === 0) {
        // If all names have been drawn, reset the selectedNames array
        selectedNames.push(...listNames);
      }

      const randomIndex = Math.floor(Math.random() * selectedNames.length);
      const randomName = selectedNames.splice(randomIndex, 1)[0];

      element.textContent = randomName;
    });
  }
};

problemsColorsAndNames();

const generateBestFeatures = () => {
  const bestItemFeatures = document.querySelectorAll(".best__item-features");

  bestItemFeatures.forEach((featureContainer) => {
    const dataFeatures = featureContainer.getAttribute("data-features");
    const featuresNames = dataFeatures.split("/").map((item) => item.trim());

    featuresNames.forEach((singleFeature) => {
      const singleFeatureDiv = document.createElement("div");
      singleFeatureDiv.classList.add("best__item-feature", "px-3", "py-2", "px-md-4", "py-md-3");
      singleFeatureDiv.textContent = singleFeature;
      featureContainer.appendChild(singleFeatureDiv);
    });
  });
};

generateBestFeatures();
