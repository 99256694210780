const transformElementsOnMouseMove = (elementsClass) => {
  const elements = document.querySelectorAll(elementsClass);

  document.addEventListener("mousemove", function (event) {
    elements.forEach((element) => {
      element.style.transform = `translate(${event.clientX * 0.02}px, ${event.clientY * 0.02}px`;
    });
  });
};

transformElementsOnMouseMove(".intro__decoration");
