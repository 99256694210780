import "../styles/main-page.scss";
import "../styles/subpages.scss";
import "./generateItems.js";
import "./sliders.js";
import "./headerScroll.js";
import "./transformOnMove.js";

if (navigator.userAgent.indexOf("Firefox") != -1) {
  document.querySelector("body").style.fontFamily = "sans-serif";
}

const whenHideSorting = document.querySelector("[data-whenHideSorting]");
if (document.querySelector("[data-whenHideSorting]") != null) {
  const hideSortingNumber = whenHideSorting.getAttribute("data-whenHideSorting");
  if (hideSortingNumber > document.querySelectorAll(".shop__list-title").length) {
    whenHideSorting.style.display = "none";
  }
}
